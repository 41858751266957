import { DcBaseComponent } from '@deleteagency/dc';
import { CountUp } from 'countup.js';
import intersectionHelper from 'general/js/intersection-helper';

export default class CountUpComponent extends DcBaseComponent {
    static getNamespace() {
        return 'count-up';
    }

    onInit() {
        let { to } = this.options;
        let suffix = '';
        let prefix = '';
        [...to].forEach((symbol) => {
            if (!this._isNumeric(symbol)) {
                const position = to.indexOf(symbol);
                if (position === 0) {
                    prefix = symbol;
                } else if (position === to.length - 1) {
                    suffix = symbol;
                }
            }
        });
        to = to.replace(/[^0-9]/g, '');
        this.count = new CountUp(this.element, to, {
            duration: 2.5,
            useGrouping: false,
            prefix,
            suffix,
        });
        intersectionHelper.enterViewportOnce(this.element, this._start);
    }

    _isNumeric = (val) => /^-?\d+$/.test(val);

    _start = () => {
        this.count.start();
    };
}
